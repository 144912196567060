<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
    >
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-auto flex-column">
          <a href="#" class="text-center mb-4 mt-4">
            <img
              src="media/comunicante/logo-wellon.png"
              class="max-h-50px"
              alt=""
            />
          </a>
        </div>
        <div class="d-flex flex-column-fluid flex-center">
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" ref="frmRecuperarSenha">
              <div class="pb-4 pt-lg-0 pt-4">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Recuperar Senha
                </h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email</label
                >
                <div>
                  <input
                    class="form-control form-control-solid py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    placeholder="Email"
                    v-model="form.email"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Token</label
                >
                <div>
                  <input
                    class="form-control form-control-solid py-7 px-6 rounded-lg"
                    type="text"
                    name="token"
                    placeholder="Token"
                    v-model="form.token"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Nova Senha</label
                >
                <div>
                  <input
                    class="form-control py-7 px-6 rounded-lg"
                    type="password"
                    name="novaSenha"
                    placeholder="Nova Senha"
                    v-model="form.novaSenha"
                    autocomplete="new-password"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Confirmar Nova Senha</label
                >
                <div>
                  <input
                    class="form-control py-7 px-6 rounded-lg"
                    type="password"
                    name="confirmarNovaSenha"
                    placeholder="Confirmar Nova Senha"
                    v-model="form.confirmarNovaSenha"
                    autocomplete="new-password"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="btnSubmit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 my-3 mr-3"
                >
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import { mapGetters } from "vuex";
import { SAVE_RESET_PASSWORD } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

import base64url from "base64url";

export default {
  data() {
    return {
      form: {
        email: "",
        token: "",
        novaSenha: "",
        confirmarNovaSenha: ""
      }
    };
  },

  computed: {
    ...mapGetters(["validatorErrors"])
  },

  mounted() {
    this.form.email = base64url.decode(this.$route.params.email);
    this.form.token = this.$route.params.token;

    const form = this.$refs["frmRecuperarSenha"];

    this.fv = formValidation(form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        token: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        },
        novaSenha: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            stringLength: {
              min: 12,
              max: 20,
              message:
                "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
            },
            regexp: {
              regexp: /^(?=.*[A-Za-z])(?=.*[0-9]).{12,20}$/,
              message:
                "A senha deve ter de 12 a 20 caracteres e pelo menos 1 letra e 1 número."
            },
            blank: {}
          }
        },
        confirmarNovaSenha: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            identical: {
              message:
                'O campo "Nova Senha" e "Confirmar Nova Senha" devem ser iguais.',
              compare: function () {
                return this.frmCadastrarUsuario.senha;
              }
            },
            blank: {}
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(SAVE_RESET_PASSWORD, this.form)
        .then(() => {
          Swal.fire({
            title: "MENSAGEM: ",
            text: "Senha recuperada com suceso!",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false
          });

          this.$router.push({ name: "login" });
        })
        .catch(() => {
          for (const error in this.validatorErrors) {
            this.fv
              .updateValidatorOption(
                error,
                "blank",
                "message",
                this.validatorErrors[error]
              )
              .updateFieldStatus(error, "Invalid", "blank");
          }
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });
  }
};
</script>
